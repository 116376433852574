import spacer from "./images/spacer-right.png"

const RightSpacer = () => {
   return ( 
       <div className="image-section1-desktop">
           <img
               src={spacer}
               alt="spacer"
               width = "164"
               height = "79"
               style={{border: "0", display: "inline"}}
           />
       </div>
   );
}
 
export default RightSpacer;
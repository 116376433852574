import button from "./images/subscribe-button.png"
const SubscribeButton = () => { 
    return ( 
        <input type="image" 
        src= {button} 
        alt="Subscribe" 
        name="submit" 
        id="submit" 
        value="Subscribe"
        style={{display:"block"}} /> 
    );
}
 
export default SubscribeButton;
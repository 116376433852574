import Buisness from "./navbar-components/Business";
import Community from "./navbar-components/Community";
import LeftSpacer from "./navbar-components/LeftSpacer";
import Logo from "./navbar-components/Logo";
import Mytbaytel from "./navbar-components/Mytbaytel";
import Personal from "./navbar-components/Personal";
import RightSpacer from "./navbar-components/RightSpacer";
import Support from "./navbar-components/Support";

const Navbar = () => {
    return ( 
        
        <div className="headerSection">
            <LeftSpacer />
            <Logo />
            <Personal />
            <Buisness />
            <Mytbaytel />
            <Support />
            <Community />
            <RightSpacer />
        </div>
        
     );
}
 
export default Navbar;
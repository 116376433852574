import logo from "./images/logo.png"

const Logo = () => {
    return (
        <div className="image-section1">
            <a href="http://www.tbaytel.net/">
                <img
                    src={logo}
                    alt="Tbaytel"
                    width= "139" 
                    height= "79"
                    style={{border: "0", display: "inline"}}
                />
            </a>
        </div>
    );
  };
  
  export default Logo;
import { useState } from "react";
import SubscribeButton from "./SubscribeButton";
import axios from "axios";
import triggerBody from "./TriggerRequest";
import Navbar from "./Navbar";

const SendTriggerForm = () => {

    const [header, setHeader] = useState("SUBSCRIBE");
    const [email, setEmail] = useState("");
    const [errorText, setErrorText] = useState("");
    const [userSubmit, setUserSubmit] = useState(false);
    const [badEmail, setBadEmail] = useState(false);

    /**
     * Used to check the validity of the email.
     * @param {String} email Passed in from the email input.
     * @returns True if the email is of vliad form, false otherwise.
     */
    function validateEmail(email){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //return the opposite
        return !re.test(String(email).toLowerCase()); 
    }

    /**
     * Sends a GET req to trigger/send endpoint using the users email, and the name for the trigger in the sendBody.
     * @param {event} event Generated on form submit
     */
    const handleTriggerRequest = async(event) => {
        event.preventDefault();
        const axiosConfig = { 
            headers : {
                appID: '49D2d&c74$vDF83!34@2C087Dd'
            }
        }
        if(email){
            console.log("Email")
            if(!validateEmail(email)){
                console.log("Validate");
                const sendBody = triggerBody;
                sendBody.send.email = email;
                sendBody.send.triggerName = "Verify User";
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/trigger/send`, sendBody.send, axiosConfig)
                .then(res => {
                    setUserSubmit(true);
                    setHeader("CHECK YOUR EMAIL")
                });
            } else {
                setBadEmail(true);
            }
        }
    }

    return (
        <div className="mainContent">
            
            <Navbar />
            <div className="mobile-show">
                <a className="header-links" href="http://www.tbaytel.net/">PERSONAL</a>&nbsp;&nbsp;&nbsp;<a  className="header-links" href="http://www.tbaytel.net/business">BUSINESS</a>&nbsp;&nbsp;<a  className="header-links" href="https://www.tbaytel.net/mytbaytel">MYTBAYTEL</a>&nbsp;&nbsp;<a  className="header-links" href="http://www.tbaytel.net/support/gethelp">SUPPORT</a>
            </div>
        
            <div className="main-section">
                <div className="rounded-section">
                    <div id="green-title" >
                        <span className="green-title" >
                            {header}
                        </span>
                        <br /><br />
                        {userSubmit ?
                        <>Thank you, you should receive an email shortly with a secure link to edit/create your account.</>
                        :
                        <>Please enter your email to receive a secure link which can be user to setup or update your account.</>
                        }
                        
                        
                    </div>
                </div>
                <div className="inside-section">
                    <div className="form-section"  id="mainContent">
                        <form id="contact" name="contact" onSubmit={handleTriggerRequest}>
                            <input type="hidden" name="supersecretvalue" value="supersecretvalue" />
                            <p className="small-size"><span className="blue-title">*Required</span></p>
                            <br style={{clear:"both"}}/>
                                <label htmlFor="email"><span className="blue-title">*</span>Email Address</label>
                                <input type="text" value={ email } name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                                <div className={errorText} id="error" style={{color:"#A50707"}}> </div>
                            <div className="submitButtonContainer">
                                <label htmlFor="submit" style={{display: "none"}}>submit</label>
                                <SubscribeButton value="Submit"/>
                            </div>
                        </form>
                    </div>    
                    
                </div>  
            </div>  
        </div>
)
}

export default SendTriggerForm;


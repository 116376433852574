import support from "./images/support.png"

const Support = () => {
    return ( 
    <div className="image-section1-desktop">
        <a href="http://www.tbaytel.net/support/gethelp">
            <img
                src={support}
                alt="SUPPORT"
                width = "102"
                height = "79"
                style={{border: "0", display: "inline"}}
            />
            </a>
        </div> 
    );
}
 
export default Support;
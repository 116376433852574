import { useState } from "react";
import UnsubscribeButton from "./UnsubscribeButton";
import axios from "axios";
import unsubscribeBody from "./UnsubscribeRequest";

const UnsubscribeForm = () => {

    const [email, setEmail] = useState("");
    const [all, setAll] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const [opinion, setOpinion] = useState(false);
    const [newAtTBay, setNewAtTBay] = useState(false);

    function handleOpinion() {
        setOpinion(!opinion);
    };

    function handleNewAtTBay() {
        setNewAtTBay(!newAtTBay);
    };

    function handleNewsletter(){
        setNewsletter(!newsletter);
    };

    function handleAll(){
        setAll(!all);
    };

    function resetErrors(){
        document.getElementById("errorsUnsubscribe").innerHTML = "";
    }

    function validateEmail(email){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //return the opposite
        return !re.test(String(email).toLowerCase()); 
    }

    function validateForm() {
        resetErrors();
        var success = true;
        if (email === ""){
            document.getElementById("errorsUnsubscribe").innerHTML += "Please enter your email";
            success = false;
        } else if (validateEmail(email)){
            document.getElementById("errorsUnsubscribe").innerHTML += "Please enter a valid email";
            success = false;
        }
        return success;
    }

    function updateSendBody(sendBody){
        let date = new Date();
        let isoDate = date.toISOString();
        sendBody.contactLastUpdatedDateTime = isoDate;
        sendBody.email = email;
        if (!all){
            
            if (newAtTBay) {
                sendBody.subscriptionPermissions[2].permissionCaptureDateTime = isoDate;
            } else {
                sendBody.subscriptionPermissions.splice(2, 1);
            }
            
            if (opinion) {
                sendBody.subscriptionPermissions[1].permissionCaptureDateTime = isoDate;
            } else {
                sendBody.subscriptionPermissions.splice(1, 1);
            }

            if (newsletter){
                sendBody.subscriptionPermissions[0].permissionCaptureDateTime = isoDate;
            } else {
                sendBody.subscriptionPermissions.splice(0, 1);
            }
        }
    }
    
    const handleUnsubscribeRquest = async (event) => {
        event.preventDefault();
        if ( validateForm() ){
            const sendBody = unsubscribeBody;
            updateSendBody(sendBody);

            const axiosConfig = {
                headers: {
                    appid: "49D2d&c74$vDF83!34@2C087Dd"
                },
            };

            try {
                await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/api/unsubscribe`,
                    sendBody,
                    axiosConfig
                );
                //show success message
                document.getElementById("mainContent").style.display = "none";
                document.getElementById("orange-title").style.display = "none";
                document.getElementById("thanksContent").style.display = "block";
            } catch  (error) {
                console.error(error);
                document.getElementById("mainContent").style.display = "none";
                document.getElementById("orange-title").style.display = "none";
                document.getElementById("sorryContent").style.display = "block";
                //display error
            }
        }
    };
    
    return ( 
        <div>
            <div className="main-section">
                <div className="rounded-section">
                        <div id="orange-title" >
                            <span className="orange-title" >
                                UNSUBSCRIBE
                            </span>
                            <br /><br />
                            To unsubscribe from the following communications, please enter your email address, then check the appropriate boxes.
                        </div>
                        <div id="sorryContent" style={{display:"none"}}>
                            <center>
                                Service is not available at the moment, Please try again later.
                            </center>
                        </div>
                        <div id="thanksContent" style={{display:"none"}}>
                            <center>
                            You have now been removed from the communication list.  You are able to resubscribe at any time.
                            </center>
                        </div>
                    </div>
                </div>
            <p className="small-size">&nbsp;</p>
            <div id="mainContent" className="inside-section">
                <div className="form-section">
                    <form id="contact" name="contact" onSubmit={handleUnsubscribeRquest}>
                        <input type="hidden" name="supersecretvalue" value="supersecretvalue" />
                        <label htmlFor="email">*Email Address</label>
                        <input type="text" value={ email } onChange={(e) => setEmail(e.target.value)} name="email" id="email" />
                        <div className="error" id="errorsUnsubscribe" >
                                
                        </div>
                        <br /><br />
                
                        
                        <div className="optional-fields">
                            <div > 
                                <input type="checkbox" onChange={(e) => handleNewsletter()} value="newsletter_promotion_unsubscribe" id ="unsubscribe_0" /><label className="checkbox-label" htmlFor="unsubscribe_0"><strong>Newsletter & Promotions</strong></label>
                                <input type="checkbox" onChange={(e) => handleOpinion()} value="opinion_unsubscribe" id="unsubscribe_1" /><label className="checkbox-label" htmlFor="unsubscribe_1"><strong>Your Opinion</strong> </label>
                                <input type="checkbox" onChange={(e) => handleNewAtTBay()} value="WhatsNew_unsubscribe" id="unsubscribe_2"/><label className="checkbox-label" htmlFor="unsubscribe_2"><strong>What's New at Tbaytel</strong> </label>
                                <input type="checkbox" onChange={(e) => handleAll()} value="all_unsubscribe" id="unsubscribe_3" /><label className="checkbox-label" htmlFor="unsubscribe_3"><strong>All</strong> </label>
                            </div>
                            <div className="error" id="errorsUnsubscribe2" >
                            
                        
                            </div>
                            <br />
                            <br style={{clear:"both"}} /><br style={{clear:"both"}} /><br style={{clear:"both"}} /><br style={{clear:"both"}} />
                            <center>
                            <label htmlFor="submit" style={{display: "none"}}>submit</label> 
                            <UnsubscribeButton />
                        
                            <br />
                    Your unsubscribe request will be processed within 10-business days.<br />

                        Please note that you may still receive transactional type emails from Tbaytel.</center>
                    
                    
                        </div>
                    </form>
                </div>
            </div>
        </div>
     );
}
 
export default UnsubscribeForm;
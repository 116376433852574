const subscribeBody = {
  email: "",
  id: "",
  fields: [
    {
      name: "email",
      value: "",
    },
    {
      name: "CUS_CUSTOMER_ID",
      value: "",
    },
    {
      name: "BILLING_POINT",
      value: "",
    },
    {
      name: "firstname",
      value: "",
    },
    {
      name: "lastname",
      value: "",
    },
    {
      name: "postalcode",
      value: "",
    },
    {
      name: "customer",
      value: "",
    },
    {
      name: "subscription_type",
      value: "",
    },
    {
      name: "Mobility",
      value: "",
    },
    {
      name: "Home Phone",
      value: "",
    },
    {
      name: "Internet",
      value: "",
    },
    {
      name: "Digital TV",
      value: "",
    },
    {
      name: "Security",
      value: "",
    },
    {
      name: "newsletter_supscription",
      value: "",
    },
    {
      name: "opinion_supscription",
      value: "",
    },
    {
      name: "WhatsNew_supscription",
      value: "",
    },
    {
      name: "source",
      value: "",
    },
    {
      name: "Service Class",
      value: "",
    },
    {
      name: "domain",
      value: "",
    },
    {
      name: "Tbaytel_Neighbours_Member",
      value: "",
    },
    {
      name: "Conferencing",
      value: "",
    },
    {
      name: "Business Name",
      value: "",
    },
    {
      name: "Internet Plan",
      value: "",
    }
  ],
  subscriptionPermissions: [
    {
      name: "Newsletter",
      permission: "Unassigned",       
    },
    {
      name: "opinion",
      permission: "Unassigned",
    },
    {
      name: "WhatsNew",
      permission: "Unassigned",
    }
  ],
};

export default subscribeBody;

// const subscribeBody = {
//   email: "",
//   id: "",
//   subscriptionPermissions: [
//     {
//       name: "Newsletter",
//       permission: "Unassigned",       
//     },
//     {
//       name: "opinion",
//       permission: "Unassigned",
//     },
//     {
//       name: "WhatsNew",
//       permission: "Unassigned",
//     }
//   ],
// };
  
//   export default subscribeBody;

  //ORIGINAL TEMPLATE
//   const subscribeBody = {
//     "email": "",
//     "fields": [
//       {
//         "field": {
//           "id": 2,
//           "name": "CUS_CUSTOMER_ID",
//           "type": "Number",
//           "required": false,
//           "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//           "id": 3,
//           "name": "BILLING_POINT",
//           "type": "Number",
//           "required": false,
//           "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 4,
//             "name": "firstname",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 5,
//             "name": "lastname",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 6,
//             "name": "postalcode",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 7,
//             "name": "customer",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 8,
//             "name": "subscription_type",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 9,
//             "name": "Mobility",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 10,
//             "name": "Home Phone",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 11,
//             "name": "Internet",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 12,
//             "name": "Digital TV",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 13,
//             "name": "Security",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 14,
//             "name": "newsletter_supscription",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 15,
//             "name": "opinion_supscription",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 16,
//             "name": "WhatsNew_supscription",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 17,
//             "name": "source",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 18,
//             "name": "Service Class",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 19,
//             "name": "domain",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 20,
//             "name": "Tbaytel_Neighbours_Member",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 21,
//             "name": "Conferencing",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 22,
//             "name": "Business Name",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       },
//       {
//         "field": {
//             "id": 23,
//             "name": "Internet Plan",
//             "type": "Text",
//             "required": false,
//             "isIdentifier": false
//         },
//         "value": ""
//       }
//     ],
//   "version": {
//       "version": 1,
//       "createdDate": "2016-06-09T15:04:11.433Z"
//   },
//   "source": {
//       "id": 8,
//       "name": "WhatsNew_unsubscribe_2016-06-09",
//       "type": "APIListUploads",
//       "createdDate": "2016-06-09T13:39:51.457Z"
//   },
//   "subscriptionPermissions": [
//       {
//           "id": 1,
//           "name": "Newsletter",
//           "permission": "Unsubscribed",
//           "permissionCaptureDateTime": "2016-06-09T15:04:11.763Z"
//       },
//       {
      
//           "id": 2,
//           "name": "opinion",
//           "permission": "Unsubscribed",
//           "permissionCaptureDateTime": "2016-06-09T15:04:12.113Z"
//       },
//       {
        
//           "id": 3,
//           "name": "WhatsNew",
//           "permission": "Unsubscribed",
//           "permissionCaptureDateTime": "2016-06-09T15:04:12.46Z"
//     }
//   ],
//   "contactCreatedDateTime": "2016-06-09T15:04:11.433Z",
//   "contactLastUpdatedDateTime": "2016-06-09T15:04:12.46Z",
//   "contactTypes": null
// };
  
//   export default subscribeBody;
  
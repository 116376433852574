import "./App.css";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import Subscribe from "./Subscribe";
import SendTriggerForm from "./SendTriggerForm";
import Legal from "./Legal";
import Unsubscribe from "./Unsubscribe";
import SubscribeConfirmationSubscription from "./SubscribeConfirmSubscription";


function App() {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <div className="App">
          <Route exact path="/">
            <Redirect to="/preferenceCenters/subscribe.php" />
          </Route>
          <Route path="/preferenceCenters/subscribe.php" exact component={() => <SendTriggerForm />} />
          <Route path="/preferenceCenters/unsubscribe.php" exact component={() => <Unsubscribe />} />
          <Route path="/preferenceCenters/editPreferences.php" exact component={() => <Subscribe />} />
          <Legal />
        </div>
      </QueryParamProvider>
    </Router>
  );
}

export default App;

const Legal = () => {
    return ( 

        <div className="legal">
                <center>
                    1046 Lithium Dr. | Thunder Bay | ON | P7B 6G3 | 1-800-264-9501<br />
                    <a href="http://www.tbaytel.net/corporate/privacy" target="_blank" >
                    Privacy Policy
                    </a>
                </center>
            </div>

     );
}
 
export default Legal;
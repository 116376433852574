import buisness from "./images/business.png"

const Buisness = () => {
    return ( 
        <div className="image-section1-desktop">
            <a href="http://www.tbaytel.net/business">
                <img
                    src={buisness}
                    alt="BUISNESS"
                    width = "105"
                    height = "79"
                    style={{ border: "0", display: "inline"}}
                />
            </a>
        </div>
     );
}
 
export default Buisness;
import spacer from "./images/spacer-right.png"

const Community = () => {
    return ( 
        <div className="image-section1-desktop">
            <img
                src={spacer}
                alt="PERSONAL"
                width="110"
                height="79"
                style={{border: "0", display: "inline"}}
            />
        </div>
     );
}
 
export default Community;
import Navbar from "./Navbar";
import SubscribeForm from "./SubscribeForm";
import { useTitle } from "./useTitle";

const Subscribe = () => {

    useTitle("Subscribe");

    return ( 
        <div className="mainContent">
            
            <Navbar />
            <div className="mobile-show">
                <a className="header-links" href="http://www.tbaytel.net/">PERSONAL</a>&nbsp;&nbsp;&nbsp;<a  className="header-links" href="http://www.tbaytel.net/business">BUSINESS</a>&nbsp;&nbsp;<a  className="header-links" href="https://www.tbaytel.net/mytbaytel">MYTBAYTEL</a>&nbsp;&nbsp;<a  className="header-links" href="http://www.tbaytel.net/support/gethelp">SUPPORT</a>
            </div>
        
            <SubscribeForm />
        </div>
     );
}
 
export default Subscribe;
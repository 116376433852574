import Navbar from "./Navbar";
import UnsubscribeForm from "./UnsubscribeForm"
import { useTitle } from "./useTitle";
const Unsubscribe = () => {
    useTitle("Unsubscribe");
    return ( 
        <div className="mainContent">
            <Navbar />
            <div className="mobile-show">
                <a className="header-links" href="http://www.tbaytel.net/">PERSONAL</a>&nbsp;&nbsp;&nbsp;<a  className="header-links" href="http://www.tbaytel.net/business">BUSINESS</a>&nbsp;&nbsp;<a  className="header-links" href="https://www.tbaytel.net/mytbaytel">MYTBAYTEL</a>&nbsp;&nbsp;<a  className="header-links" href="http://www.tbaytel.net/support/gethelp">SUPPORT</a>
            </div>
            <UnsubscribeForm />
        </div>
     );
}
 
export default Unsubscribe;
import personal from "./images/personal.png"

const Personal = () => {
    return ( 
        <div className="image-section1-desktop">
            <a href="http://www.tbaytel.net/">
                <img
                    src={personal}
                    alt="PERSONAL"
                    width = "126"
                    height = "79"
                    style={{border: "0", display: "inline"}}
                />
            </a>
        </div>
     );
}
 
export default Personal;
import { useEffect, useState } from "react";
import SubscribeButton from "./SubscribeButton";
import axios from "axios";
import subscribeBody from "./SubscribeRequest";
import createBody from "./CreateRequest";
import LoadingScreen from "./LoadingScreen";
import triggerBody from "./TriggerRequest";
const SubscribeForm = () => {

    //http://connect-preference-api-dev.ds03.internal.tekside.io
    const lock = 0;
    const [validId, setValidId] = useState(false);
    const [contactData, setContactData] = useState([]);
    const [newUser, setNewUser] = useState(false);
    const [enableForm, setEnableForm] = useState(true);
    const [loading, setLoading] = useState(true);
    const [alreadySub, setAlreadySub] = useState(false);

    const [email, setEmail] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [postalcode, setPostalcode] = useState("");
    const [hasMobility, setHasMobility] = useState(false);
    const [hasPhone, setHasPhone] = useState(false);
    const [hasInternet, setHasInternet] = useState(false);
    const [hasDigitalTV, setHasDigitalTV] = useState(false);
    const [hasSecurity, setHasSecurity] = useState(false);
    const [opinion, setOpinion] = useState(false);
    const [newAtTBay, setNewAtTBay] = useState(false);
    const [isCustomer, setIsCustomer] = useState(false);
    const [errorText, setErrorText] = useState("error");

    /**
     * Set's all the hooks that contain subscription information with the data received from the GET req
     * @param  {[Array]} data A JSON object containing all the contact information recieved from the GET req
     */ 
    const populateSubscriptions = (data) => {
        var subscriptions = data[0].subscriptions;
        for(let s of subscriptions){
            console.log(s.subscriptionPermission);
            if (s.subscriptionPermission.name === "opinion" && s.subscriptionPermission.permission === "Express"){
                setOpinion(true);
            } else if(s.subscriptionPermission.name === "WhatsNew" && s.subscriptionPermission.permission === "Express"){
                setNewAtTBay(true);
            }
        }
    }

/**
     * Set's all the hooks that contain field information with the data received from the GET req
     * @param  {[Array]} data A JSON object containing all the contact information recieved from the GET req
     */ 
 const populateFields = (data) => {
    var rawFields = JSON.parse(JSON.stringify(data[0].fields));
    console.log(rawFields);
    setFirstname(rawFields.firstname);
    setLastname(rawFields.lastname);
    setPostalcode(rawFields.postalcode);
    if(rawFields.customer === "Yes"){
        setIsCustomer(true);
        handleIsCustomer("Yes");
        //ADD handling for when NO
    }
    if(rawFields.Mobility === "1"){
        setHasMobility(true);
    }
    if(rawFields["Home Phone"] === "1"){
        setHasPhone(true);
    }
    if(rawFields.Internet === "1"){
        setHasInternet(true);
    }
    if(rawFields["Digital TV"] === "1"){
        setHasDigitalTV(true);
    }
    if(rawFields.Security === "1"){
        setHasSecurity(true);
    }
}

    /**
     * Checks whether all the subscriptions in passed in list are of permission Unassigned
     * @param  {[Array]} subscriptionList An array containing the subscriptionPermissions for the contact
     * @return {[boolean]} Returns true if all are unassigned, otherwise false
     */ 
    const isNewUser = (subscriptionList) => {
        let isNew = true;
        for(let s of subscriptionList) {
            if(s.subscriptionPermission.permission !== "Unassigned") {
                isNew = false;
            }
        }
        setNewUser(isNew);
    }  

    /**
     * Set's the contactData state with the information returned from doing a GET req with the users email, if they exist. If the user 
     * does not exist a message will be shown to the user informing them to fill out the fields and create their account.
     * @param  {[string]} email A string containing the users email.
     * @return {[void]} Returns nothing.
     */
    const getContact = (email) => {
        setValidId(true);
        setEmail(email);
        const axiosConfig = { 
            headers : {
                appID: '49D2d&c74$vDF83!34@2C087Dd'
            }
        }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/subscribe?email=${email}`, axiosConfig)
        .then(response => {
            if(response.status === 200) {
                setContactData([...response.data.content]);
                isNewUser(response.data.content[0].subscriptions);
                setEnableForm(true);
                setLoading(false);
                populateSubscriptions(response.data.content);
                populateFields(response.data.content);
            } else {
            console.log("Something went wrong.");
            }
        });
    }

    /**
     * Resets any error messages that were created from the user entering invalid input.
     */
    function resetErrors(){
        
        document.getElementById("error").innerHTML = "";  //Email
        document.getElementById("error2").innerHTML = ""; //Firstname
        document.getElementById("error3").innerHTML = ""; //Lastname
        document.getElementById("error4").innerHTML = ""; //Postalcode
        document.getElementById("error5").innerHTML = "";
        document.getElementById("error6").innerHTML = "";
        setErrorText("error");
        setAlreadySub(false);
    }


    /**
     * Used to check the validity of the email.
     * @param {String} email Passed in from the email input.
     * @returns True if the email is of vliad form, false otherwise.
     */
    function validateEmail(email){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //return the opposite
        return !re.test(String(email).toLowerCase()); 
    }

    /**
     * Used to check the validity of the postal code.
     * @param {String} postalcode Passed in from the postal code input.
     * @returns True if the postal code is of valid form, false otherwise.
     */
    function validatePostalcode(postalcode){
        var re = /^[a-zA-Z]{1}\d{1}[a-zA-Z]{1} *\d{1}[a-zA-Z]{1}\d{1}$/
        return !re.test(String(postalcode).toLowerCase());
    }

    /**
     * Checks whether the form the user has filled out is of valid form.
     * @returns true if the form is valid, false otherwise
     */
    function validateForm() {
        resetErrors();
        var success = true;
        if (email === ""){
            document.getElementById("error").innerHTML += "Please enter your email";
            setTimeout(() => {
                setErrorText("error-blink");
            }, 100);
            success = false;
        } else if (validateEmail(email)){
            document.getElementById("error").innerHTML += "Please enter a valid email";
            setTimeout(() => {
                setErrorText("error-blink");
            }, 100);
            success = false;
        }

        if (firstname === ""){
            document.getElementById("error2").innerHTML += "Please enter your firstname";
            setTimeout(() => {
                setErrorText("error-blink");
            }, 100);
            success = false;
        }
        if (lastname === ""){
            document.getElementById("error3").innerHTML += "Please enter your lastname";
            setTimeout(() => {
                setErrorText("error-blink");
            }, 100);
            success = false;
        }

        if (postalcode === ""){
            document.getElementById("error4").innerHTML = "Please enter your postalcode";
            setTimeout(() => {
                setErrorText("error-blink");
            }, 100);
            success = false;
        } else if (validatePostalcode(postalcode)){
            document.getElementById("error4").innerHTML = "Please enter a valid postalcode";
            setTimeout(() => {
                setErrorText("error-blink");
            }, 100);
            success = false;
        }
        return success;
    }

    /**
     * Used to show checkbox's for selecting what services you use, as well as setting them to false if the user selects no.
     * @param {String} radioValue The value for whether the user is a customer or not.
     */
    function handleIsCustomer(radioValue) {
         var customer = radioValue;
        if (customer === "Yes"){
            document.getElementById("showMe").style.display = "block";
            setIsCustomer(true);
        } else {
            document.getElementById("showMe").style.display = "none";
            setIsCustomer(false);
            setHasMobility(false);
            setHasPhone(false);
            setHasInternet(false);
            setHasDigitalTV(false);
            setHasSecurity(false);
        }

    }

    /*
    Functions for setting the hook values on checkbox changes.
    */
    function handleHasMobility() {
        setHasMobility(!hasMobility);
    };
    function handleHasPhone() {
        setHasPhone(!hasPhone);
    };
    function handleHasInternet() {
        setHasInternet(!hasInternet);
    };
    function handleHasDigitalTV() {
        setHasDigitalTV(!hasDigitalTV);
    };
    function handleHasSecurity() {
        setHasSecurity(!hasSecurity);
    };
    function handleOpinion() {
        setOpinion(!opinion);
    };
    function handleNewAtTBay() {
        setNewAtTBay(!newAtTBay);
    };

    /**
     * Sets all the values in the body that gets sent to the POST req.
     * @param {Object} sendBody The JS object used in the POST req.
     */
    function updateSendBody(sendBody){
    
        sendBody.fields[0].value = email;
        sendBody.fields[3].value = firstname;
        sendBody.fields[4].value = lastname;
        sendBody.fields[5].value = postalcode;
        sendBody.fields[6].value = (isCustomer) ? "Yes" : "No";
        sendBody.fields[8].value = (hasMobility) ? "1" : "0";
        sendBody.fields[9].value = (hasPhone) ? "1" : "0";
        sendBody.fields[10].value = (hasInternet) ? "1" : "0";
        sendBody.fields[11].value = (hasDigitalTV)  ? "1" : "0";
        sendBody.fields[12].value = (hasSecurity)  ? "1" : "0";

        let date = new Date();
        let isoDate = date.toISOString();
        if (newAtTBay) {
            sendBody.subscriptionPermissions[2].permission = "Express";
        } else {
            sendBody.subscriptionPermissions[2].permission = "Unsubscribed";
        }
        sendBody.subscriptionPermissions[2].permissionCaptureDateTime = isoDate;
        if (opinion) {
            sendBody.subscriptionPermissions[1].permission = "Express";
        } else {
            sendBody.subscriptionPermissions[1].permission = "Unsubscribed";
        }
        sendBody.subscriptionPermissions[1].permissionCaptureDateTime = isoDate;

        sendBody.subscriptionPermissions[0].permission = "Express";
        sendBody.subscriptionPermissions[0].permissionCaptureDateTime = isoDate;
    }
    /**
     * On page load parses the id from the URL and verifies the token. 
     * If the token is valid the email is used to retrieve the contact information
     */
    useEffect(() => {
        
        const axiosConfig = { 
            headers : {
                appID: '49D2d&c74$vDF83!34@2C087Dd'
            }
        }
        // eslint-disable-next-line no-restricted-globals
        let params = new URLSearchParams(location.search);
        const tokenId = params.get("id");
        if(tokenId !== "") {
            let sendBody = triggerBody;
            sendBody.verify.id = tokenId;
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/trigger/verify`, sendBody.verify, axiosConfig).then(response => {
                if(response.data.success) {
                    getContact(response.data.email);
                } else {
                    console.log("Something went wrong");
                    console.log(response);
                }
            });
        }
    }, [lock])

    const handleSubscribeRequest = async (event) => {
        event.preventDefault();
        if (validateForm() && validId){
            
            const sendBody = subscribeBody;
            
            //appid: "49D2d&c74$vDF83!34@2C087Dd"
            const axiosConfig = {
                headers: {
                    appid: "49D2d&c74$vDF83!34@2C087Dd"
                },
            };
            updateSendBody(sendBody);
            sendBody.email = email;
            console.log(sendBody);
            try {
                axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/api/subscribe`,
                    sendBody,
                    axiosConfig
                ).then(response => {
                    //User was not subscribed to the service
                    console.log("User subscribed" + response);
                    //show success message
                    document.getElementById("mainContent").style.display = "none";
                    document.getElementById("green-title").style.display = "none";
                    document.getElementById("thanksContent").style.display = "block";
                }).catch((error => {
                    //User is already subscribed to the service
                    console.log(error);
                    setAlreadySub(true);
                }));
                
            } catch (error) { 
                console.error(error);
            }
        }
    };

    
    return ( 
        <>
        {validId ?
        <div>
            <div className="main-section">
                <div className="rounded-section">
                    <div id="green-title" >
                        <span className="green-title" >
                            SUBSCRIBE
                        </span>
                        <br /><br />
                        To start receiving exclusive offers and news from Tbaytel, simply fill in the form below and click Subscribe.
                    </div>
                    <div id="sorryContent" style={{display:"none"}}>
                        <center>
                            Service is not available at the moment, Please try again later.
                        </center>
                    </div>
                    <div id="thanksContent" style={{display:"none"}}>
                        <center>
                            Thank you for subscribing. Your preferences have been updated.
                        </center>
                    </div>
                </div>
                
                <div className="inside-section">
                    <div className="form-section"  id="mainContent">
                        <form id="contact" name="contact" onSubmit={handleSubscribeRequest}>
                            <input type="hidden" name="supersecretvalue" value="supersecretvalue" />
                            <p className="small-size"><span className="blue-title">*Required</span></p>
                            <br style={{clear:"both"}}/>
                                <label htmlFor="email"><span className="blue-title">*</span>Email Address</label>
                                <input type="text" value={ email } name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                                <div className={errorText} id="error" style={{color:"#A50707"}}> </div>

                                <br /><br />
                                <label htmlFor="firstname"><span className="blue-title">*</span>First Name</label>
                                <input type="text" value={ firstname } name="firstname" id="firstname" onChange={(e) => setFirstname(e.target.value)} />
                                <div className={errorText} id="error2" style={{color:"#A50707"}}><br /></div>
                                
                                <br /><br />
                                <label htmlFor="lastname"><span className="blue-title">*</span>Last Name</label>
                                <input type="text" value={ lastname } name="lastname" id="lastname" onChange={(e) => setLastname(e.target.value)} />
                                <div className={errorText} id="error3" style={{color:"#A50707"}}><br /></div>

                                <br /><br />
                                <label htmlFor="postalcode"><span className="blue-title">*</span>Postal Code</label>
                                <input type="text" value={ postalcode } name="postalcode" id="postalcode" onChange={(e) => setPostalcode(e.target.value)} />
                                <div className={errorText} id="error4" style={{color:"#A50707"}}><br /></div>
                                
                                <br /><br />
                                <div className="optional-fields">
                                    <span className="provider-select"><span className="blue-title">*</span>Are you a Tbaytel Customer?</span><br />
                                    <div className="styled-select">

                                    <fieldset>   
                                    <legend></legend>
                                        <div onChange={(e) => handleIsCustomer(e.target.value)}>
                                            <input type="radio" checked={isCustomer} name="customer" value="Yes" id="Yes" /> <label className="radio-label" htmlFor="Yes">Yes</label>
                                            <br />
                                            <input type="radio" name="customer" value="No" id="No" /> <label className="radio-label" htmlFor="No">No</label>
                                        </div>
                                    </fieldset>
                                    <div className={errorText} id="error5" ></div>   
                                    <br />
                                    <div id= "showMe" style={{display: "none"}}>
                                        <span className="provider-select" >What Tbaytel services do you have? Check all that apply:</span><br /><br />
                                        <input onChange={(e) => handleHasMobility()} checked={hasMobility} className="line-checkbox" type="checkbox" name="mobility" value="Mobility" id ="service_0" /><label className="line-checkbox-label" htmlFor="service_0"><strong>Mobility</strong></label>
                                        <input onChange={(e) => handleHasPhone()} checked={hasPhone} className="line-checkbox" type="checkbox" name="home phone" value="Home Phone" id ="service_1" /><label className="line-checkbox-label2" htmlFor="service_1"><strong>Home&nbsp;Phone</strong></label> 
                                        <input onChange={(e) => handleHasInternet()} checked={hasInternet} className="line-checkbox" type="checkbox" name="internet" value="Internet" id ="service_2" /><label className="line-checkbox-label" htmlFor="service_2"><strong>Internet</strong></label> <br style={{clear:"both"}} /><br style={{clear:"both"}} />
                                        <input onChange={(e) => handleHasDigitalTV()} checked={hasDigitalTV} className="line-checkbox" type="checkbox" name="digital tv" value="Digital TV" id ="service_3" /><label className="line-checkbox-label" htmlFor="service_3"><strong>Digital TV</strong></label> 
                                        <input onChange={(e) => handleHasSecurity()} checked={hasSecurity} className="line-checkbox" type="checkbox" name="security" value="Security" id ="service_4" /><label className="line-checkbox-label" htmlFor="service_4"><strong>Security</strong></label>
                                        <br style={{clear:"both"}} />
                                        <div className={errorText} id="error6" ></div>
                                        <br /><br />
                                    </div>
                                    <div> 
                                        <label className="provider-select" htmlFor="provider">In addition to receiving our Newsletters and Promotions, <br />please check the boxes below to subscribe to:</label><br /><br /><br /><br />
                                        <input type="checkbox" checked={opinion} onChange={(e) => handleOpinion()} name="opinion_subscription" value="opinion_supscription" id ="subscription_0" /><label className="checkbox-label" htmlFor="subscription_0"><strong>Your Opinion</strong> - You will receive invites to
            participate in Tbaytel's research initiatives to
            help us better serve our customers.</label><br /><br /><br /><br /><br />
                                        <input type="checkbox" checked={newAtTBay} onChange={(e) => handleNewAtTBay()} name="whatsNew_subscrption" value="WhatsNew_supscription" id="subscription_1" /><label className="checkbox-label" htmlFor="subscription_1"><strong>What's New at Tbaytel</strong> - You will receive
            communications about our new initiatives,
            policies and/or programs.</label><br />
                                        <br /><br /><br />
                                        </div>
                                    </div>
                                    <center>
                                    <label htmlFor="submit" style={{display: "none"}}>submit</label>
                                    <SubscribeButton/>
                                    {loading && !alreadySub && <LoadingScreen/>}
                                    
                                    <br />
                                    {(alreadySub && "You're already subscribed to this service.") || (!alreadySub && "You may unsubscribe at anytime.")}
                                    </center>
                                </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        :
        <div className="rounded-section-centered">
            <p>
                Your secure link seems to have a bad ID. This can occur if the ID is expired or if it was edited. 
                To resolve this issue please click here to send another secure link to the email address you'd like to subscribe with.
            </p>
            <button><a href="/">Re-Send</a></button>
        </div>
        }</>
     );
}
 
export default SubscribeForm ;
const unsubscribeBody = {
    "email": "",
    "subscriptionPermissions": [
      {
          "id": 1,
          "name": "Newsletter",
          "permission": "Unsubscribed",
          "permissionCaptureDateTime": "2016-06-09T15:04:11.763Z"
      },
      {
      
          "id": 2,
          "name": "opinion",
          "permission": "Unsubscribed",
          "permissionCaptureDateTime": "2016-06-09T15:04:12.113Z"
      },
      {
        
          "id": 3,
          "name": "WhatsNew",
          "permission": "Unsubscribed",
          "permissionCaptureDateTime": "2016-06-09T15:04:12.46Z"
    }
  ],
  "contactCreatedDateTime": "2016-06-09T15:04:11.433Z",
  "contactLastUpdatedDateTime": "2016-06-09T15:04:12.46Z",
  "contactTypes": null
};
  
  export default unsubscribeBody;
  
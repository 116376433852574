import mytbaytel from "./images/MYTbaytel.png"

const Mytbaytel = () => {
    return ( 
        <div className="image-section1-desktop">
            <a href="http://www.tbaytel.net/mytbaytel">
                <img
                    src={mytbaytel}
                    alt="MYTBAYTEL"
                    width = "120"
                    height = "79"
                    style={{border: "0", display: "inline"}}
                />
            </a>
        </div> 
    );
}
 
export default Mytbaytel;
import button from "./images/submit_Unsubscribe.jpg"
const UnsubscribeButton = () => {
    return ( 
        <input type="image" 
        src={button}
        alt="Submit" 
        name="submit" 
        id="submit" 
        value="unsubscribe" 
        style={{display:"block"}} />
     );
}
 
export default UnsubscribeButton;
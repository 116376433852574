 import spacer from "./images/spacer-left.png"

 const LeftSpacer = () => {
    return ( 
        <div className="image-section1-desktop">
            <img
                src={spacer}
                alt="spacer"
                width = "134" 
                height = "79"
                style={{border: "0", display: "inline"}}
            />
        </div>
    );
 }
  
 export default LeftSpacer;